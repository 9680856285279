function validation() {
    let validatedInputs = document.querySelectorAll('.validated-input');
    console.log(validatedInputs);
    validatedInputs.forEach(validatedInput=>{
        let validatedInputContainer = validatedInput.closest('.input-wrapper');
    let succesIcon = validatedInputContainer.querySelector('.validation-icon-succes');
    let errorIcon = validatedInputContainer.querySelector('.validation-icon-error');
    let validationMessage = validatedInputContainer.querySelector('.validation-message');
    validatedInput.addEventListener('input', function() {
        if(validatedInput.value.length<validatedInput.minLength) {
            validationMessage.style.display = 'block';
            errorIcon.style.display = 'block';
            succesIcon.style.display = 'none';
        }
        else {
            succesIcon.style.display = 'block';
            validationMessage.style.display = 'none';
            errorIcon.style.display = 'none';
        }
    })
    })
}
module.exports = validation;