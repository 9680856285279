document.addEventListener('DOMContentLoaded', function(){
    const initHistoryBlocks = require('./modules/history-blocks');
    const slider = require('./modules/slider');
    const initMask = require('./modules/get-mask');
    const initModalBtn = require('./modules/modal-btn');
    const initGallery = require('./modules/gallery');
    let dotContainer = require('./modules/dot-container');
    const validation = require('./modules/validation');
    const ajaxForm = require('./modules/ajax-form');
    const mainMenu = require('./modules/main-menu');
    const mobileMenu = require('./modules/mobile-menu');
    const initQuestions = require('./modules/questions-item');
    mobileMenu();
    mainMenu();
    initGallery();
    ajaxForm();
    validation();
    initMask();
    const initVideoPreview = require('./modules/video-preview');
    dotContainer(6);
    initModalBtn();
    initHistoryBlocks();
    initVideoPreview();
    slider();
    initQuestions();
    

})