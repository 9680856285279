function initGallery() {
    const galleries = document.querySelectorAll('.gallery');
    galleries.forEach(gallery=>{
        let gap;
        (window.innerWidth > 475)?gap = 20: gap = 8;
        const galleryOverlay = gallery.querySelector('.gallery-overlay')
        const nextBtns = gallery.querySelectorAll('.next-btn');
        const prevBtns = gallery.querySelectorAll('.prev-btn');
        const galleryPreview = gallery.querySelector('.gallery-window');
        const previewImg = galleryPreview.querySelector('img');
        const galleryItems = gallery.querySelectorAll('.gallery-item');
        galleryItems[0].classList.add('active');
        const itemWidth = galleryItems[0].clientWidth;
        const galleryPreviewImg = galleryPreview.querySelector('img');
        galleryPreviewImg.setAttribute('src',galleryItems[0].querySelector('img').dataset.src);
        const itemsContainer = gallery.querySelector('.gallery-items');
        const maxScroll = itemsContainer.scrollWidth - itemsContainer.clientWidth;
        let showItems;
        galleryIntersection(gallery.querySelectorAll('.gallery-item'));
        activeItemIndex = 0;
        galleryItems.forEach(item=>{
                item.addEventListener('click',function() {
                    changeActive(item , activeItemIndex)
                })
        })
        prevBtns.forEach(prevBtn=>{
            prevBtn.addEventListener('click', function() {
                if(activeItemIndex>0) {
                    activeItemIndex -= 1;
                }
                changeActive(showItems[activeItemIndex], activeItemIndex)
                
            })
        })
        nextBtns.forEach(nextBtn=>{
            nextBtn.addEventListener('click', function() {
                if(activeItemIndex < showItems.length -1) {
                    activeItemIndex += 1;
                }
                changeActive(showItems[activeItemIndex], activeItemIndex, activeItemIndex)
            })
        })
        
        itemsContainer.addEventListener('touchmove', function(e) {
            e.preventDefault(); // Запрет на скроллинг
        });
        
        // itemsContainer.addEventListener('dragstart', function(e) {
        //     e.preventDefault(); // Запрет на перетаскивание
        // });
        ///////////
        /////// добавление класса к видимым элементам
        function galleryIntersection(items) {
            const observer = new IntersectionObserver(entries=> {
                entries.forEach(entry => {
                entry.target.classList.toggle("show-gallery-item", entry.isIntersecting);
                showItems = gallery.querySelectorAll('.show-gallery-item');
                const src = entry.target.querySelector('img').dataset.src;
                entry.target.querySelector('img').setAttribute('src', src);
                })
           
            },{threshold:.3,})
    
            items.forEach(item => {
             observer.observe(item);
            })
        }
        //////////
        ///////////// смена активного класса
        function changeActive(item ,index){
            if(item.classList.contains('show-gallery-item')) {
                galleryPreviewImg.setAttribute('src', item.querySelector('img').getAttribute('src'));
                galleryItems.forEach(item=>item.classList.remove('active'));
                item.classList.add('active');
                showItems = gallery.querySelectorAll('.show-gallery-item');
            }
            // showItems = gallery.querySelectorAll('.show-gallery-item');
            index = Array.from(showItems).findIndex(el => el.classList.contains('active'));
            if(index === showItems.length - 1 && (itemsContainer.scrollLeft != maxScroll )) {
                itemsContainer.scrollLeft += (itemWidth + gap);
            }
            if(index === 0 && (itemsContainer.scrollLeft != 0 )) {
                itemsContainer.scrollLeft -= (itemWidth + gap);
            }
            activeItemIndex = index
            // console.log(activeItemIndex);
        }
        /////////////
    })
    
}
module.exports = initGallery;