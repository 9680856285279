function historyBlocks() {
    function addActiveClass(items,current) {
        items.forEach(item=>item.classList.remove('before'))
        for(i=0; i<current;i++) {
            items[i].classList.add('before');
        }
    }
    let histories = document.querySelectorAll(".our-history");
    histories.forEach(history=>{
        let current = 0;
        let items = history.querySelectorAll('.history-item');
        let years = history.querySelectorAll('.year');
        years[current].classList.add('active');
        items[current].classList.add('active');
        let next = history.querySelector('.next');
        let prev = history.querySelector('.prev');
        let max = items.length -1;
        let min = 0;
        years.forEach(year=>{
            year.style.width = (100 / (years.length -1))+"%";
        })
        if(window.innerWidth <475) {
            years.forEach(year=>{
                year.style.width = 25+"%";
            })
            years[1].style.width = 38+'%';
        }
        years[0].style.width = 0 +'px';
        
        document.querySelector('.years').addEventListener('touchstart', function(e) {
            e.preventDefault();
        })
        next.addEventListener('click',function() {
            if(current >= max) {
                return false
            }
            else {
                current += 1;
                items.forEach(item=>{
                    item.classList.remove('active');
                });
                if(current>2) {
                    history.querySelector('.years').scrollLeft +=40;
                }
                items[current].classList.add('active');
                console.log(current)
                years.forEach(year=>year.classList.remove('active'));
                years[current].classList.add('active');
                addActiveClass(years,current);
            }
        })
        prev.addEventListener('click',function() {
            if(current <= min) {
                return false
            }
            else {
                current -= 1;
                items.forEach(item=>{
                    item.classList.remove('active');
                });
                history.querySelector('.years').scrollLeft -=40;
                items[current].classList.add('active');
                console.log(current)
                years.forEach(year=>year.classList.remove('active'));
                years[current].classList.add('active');
                addActiveClass(years,current)
            }
        })
    })
};
module.exports = historyBlocks;