function mobileMenu() {
    let hamburger = document.querySelector('.hamburger');
    let mobileMenu = document.querySelector('.mobile-menu');
    let mobileHeader = document.querySelector('.mobile-header');

    hamburger.addEventListener('click', function() {
        hamburger.classList.toggle('active');
        mobileMenu.classList.toggle('active');
        document.body.classList.toggle('hidden');
        mobileHeader.classList.toggle('active');
    })
    mobileMenu.addEventListener('click', function(e) {
        if(e.target.classList.contains('mobile-menu-link')) {
            mobileMenu.classList.remove('active');
            document.body.classList.remove('hidden');
            hamburger.classList.remove('active');
        }
    });
    if(window.innerWidth<475) {
        window.addEventListener('scroll', function() {
            if(window.scrollY>480) {
                if(!mobileHeader.classList.contains('white')) {
                    mobileHeader.classList.add('white');
                }
                else {
                    return
                }
            }
            else {
                if(mobileHeader.classList.contains('white')) {
                    mobileHeader.classList.remove('white');
                }
                else {
                    return
                }
            }
        })
    }
}
module.exports = mobileMenu;