function mainMenu() {
    let header = document.querySelector('.header');
    if(window.innerWidth>475) {
        window.addEventListener('scroll',function() {
            if(window.scrollY>670) {
                if(!header.classList.contains('active')) {
                    header.classList.add('active')
                }
                else {
                    return
                }
            }
            else {
                if(header.classList.contains('active')) {
                    header.classList.remove('active')
                }
                else {
                    return
                }
            }
        })
    }
}
module.exports = mainMenu;