function slider() {
    const sliders = document.querySelectorAll('.slider');

    sliders.forEach(slider=>{
        ///////////////////////////
        const sliderContainer = slider.querySelector('.slider-items');
        const nextBtn = slider.querySelector('.next-btn');
        console.log(slider)
        const prevBtn = slider.querySelector('.prev-btn');
        const sliderItems = Array.from(sliderContainer.childNodes).filter(item => item.nodeType === Node.ELEMENT_NODE);
        sliderItems.forEach(item=>item.classList.add('slider-item'));
        const sliderItem = sliderContainer.querySelector('div'||'a');
        (window.clientWidth >475)? gap = 20: gap = 8;
        const itemWidth = sliderItem.clientWidth;
        const totalItemWidth = itemWidth +gap;
        console.log(totalItemWidth);
        ////////////////////////////////
        let scrollAmount = 0;
        let maxScrollAmount = sliderContainer.scrollWidth - sliderContainer.clientWidth;
        nextBtn.addEventListener('click', () => {
            
            if(scrollAmount >= maxScrollAmount){
                return
            }
            else {
                scrollAmount += totalItemWidth;
                console.log(sliderItems);
                sliderContainer.scrollLeft = scrollAmount;
            }
        });
        ///////////////////
        
        prevBtn.addEventListener('click', () => {
        if(scrollAmount<=0) {
            return
        }
        else {
            scrollAmount -= totalItemWidth;
                sliderContainer.scrollLeft = scrollAmount;
        }
        });
    })
}
module.exports = slider;